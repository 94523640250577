<template>
  <section v-if="ready">
    <PanelHeaderEquipmentList
      v-if="header"
      :title="title"
      :nav="nav"
      :connector_id="connector_id"
      icon="fa fa-dashboard"
    />
    <div class="tab-pane active" id="edit" role="tabpanel" aria-labelledby="">
      <EmptyListAlert
        v-if="isEmpty"
        class="inner-panel"
        buttonLabel="add_new_device"
        importLabel="import_devices"
        :hideImport="!$can('manage', 'EstacaoCadastro') || hideImport()"
        :isInstance="connector && connector.base_model_id != null"
        @import="importDevices()"
        :newItemPath="newItemPath"
      />
      <SearchableTable
        v-if="list && !busy"
        class="searchable-table"
        :items="list"
        :fields="fields"
        :commands="commands"
        :multiColumnOrder="false"
        :clientSort="true"
        :pagination="pagination"
        :maxResult="maxResult"
        :deepSearch="false"
        :multiSelection="multiSelection"
        :disabled="cloneEnabled"
        @select="onSelect"
        @command="onCommand"
        @multiColumnSort="onMultiColumnSort"
        @nItems="maxResult = $event"
        ref="stbl"
      >
        <template #extraButtons>
          <span
            id="export"
            class="btn btn-default"
            :disabled="list && list.length == 0"
            @click.stop.prevent="downloadCSV(connector)"
            :title="$t('hints.button_export')"
          >
            <i class="fa fa-file-excel-o" />
          </span>
          <span
            v-if="multiSelection.key"
            id="mass_remove"
            class="btn btn-default"
            :disabled="isMassRemovalDisabled"
            @click="massRemove"
            :title="$t('mass_remove')"
          >
            <i class="fa fa-trash-o" />
          </span>
        </template>
        <template #statistics>
          <ResourceStatistics
            resource="device"
            :total="(items && items.length) || 0"
            :showing="nShowing"
            icon="portal-icon device[solid]"
          />
        </template>
        <template v-slot:screen_id="entry">
          <ScreenSelectionCell :item="entry.item" :key="entry.item.id">
            <template #before>
              <i class="fa fa-alarm"></i>
            </template>
          </ScreenSelectionCell>
        </template>
        <template v-slot:toggle="entry">
          <div v-if="connector && connector.base_model">-</div>
          <ResourceToggle :item="entry.item" @update="onItemToggled" v-else />
        </template>
      </SearchableTable>
      <Spin v-else></Spin>
    </div>
    <div class="tab-pane" id="list" role="tabpanel" aria-labelledby=""></div>
    <ModalImportFile
      :connectorId="connector_id"
      :entity="importEntity"
      :hasBasic="true"
      @close="clearImportEntity"
      @resource_imported="resourceImported"
    />
  </section>
</template>

<script>
import DashboardEditPickerBase from "@/components/registration/dashboard-edit-picker-base.vue";
import SearchableTable from "@/components/searchable-table.vue";
import ResourceStatistics from "@/components/statistics/resource-statistics.vue";
import DeviceService from "@/services/device.js";
import ConnectorService from "@/services/connector.js";
import EmptyListAlert from "@/components/registration/empty-list-alert.vue";
import ModalImportFile from "@/components/modal-import-file.vue";
import ScreenSelectionCell from "@/components/registration/screen-selection-cell.vue";
import ResourceToggle from "@/components/registration/resource-toggle.vue";
import ScreenFinder from "@/utils/screen-finder.js";
import Spin from "@/components/spin.vue";
import {deviceListAdapter} from "@/services/device";

export default {
  name: "DashboardEditDevicePicker",
  components: {
    SearchableTable,
    ResourceStatistics,
    EmptyListAlert,
    ModalImportFile,
    ScreenSelectionCell,
    ResourceToggle,
    Spin
  },
  extends: DashboardEditPickerBase,
  props: {
    connector_id: {
      type: Number,
      required: true,
      default: 0
    },
    formPicker: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      ready: false,
      entity: "device",
      title: "device_list",
      nav: null,
      items: null,
      select: "edit",
      importEntity: ""
    };
  },
  computed: {
    deviceList() {
      return (this.$store.getters["dashboard/deviceList"] || []).filter(
        ({connector_id}) =>
          parseInt(connector_id) === parseInt(this.connector_id)
      );
    },
    fields() {
      const hasVirtual = (
        this?.items || []
      ).some(({data_collector_device_id, id}) =>
        data_collector_device_id &&
        parseInt(data_collector_device_id) != parseInt(id)
          ? true
          : false
      );
      return [
        {
          name: "id",
          title: "ID",
          visible: false
        },
        {
          name: "reference_id",
          title: "reference",
          visible: false
        },
        {
          name: "data_collector_device_id",
          title: "collector_id",
          visible: false,
          parser(item) {
            return item.data_collector_device_id == item.id
              ? "-"
              : item.data_collector_device_id;
          }
        },
        {
          name: "name",
          title: "device",
          format: (value) => {
            return value;
          },
          style: (item) => {
            return item?.is_reference
              ? {"font-weight": "700", opacity: "0.85"}
              : {};
          }
        },
        {
          name: "device_address",
          title: "address",
          visible: this.isMQTT ? false : true,
          style: (item) => {
            return item?.is_reference
              ? {"font-weight": "700", opacity: "0.85"}
              : {};
          }
        },
        {
          name: "screen_id",
          title: "screen",
          style: (item) => {
            return item?.is_reference
              ? {"font-weight": "700", opacity: "0.85", "white-space": "nowrap"}
              : {"white-space": "nowrap"};
          },
          parser: (item) => {
            const finder = new ScreenFinder(this, item);
            return finder?.screen?.name || "";
          }
        },
        {
          name: "virtual",
          title: "Virtual",
          visible: hasVirtual,
          parser: (item) => {
            return this.$t(
              item?.data_collector_device_id &&
                parseInt(item?.data_collector_device_id) != parseInt(item.id)
                ? "yes"
                : "no"
            );
          },
          style: (item) => {
            return item?.is_reference
              ? {
                  "font-weight": "700",
                  opacity: "0.75"
                }
              : null;
          }
        },
        {
          name: "status",
          title: "status",
          parser: (device) => {
            return this.$tc(device.is_connected ? "connected" : "disconnected");
          },
          style: (item) => {
            return item?.is_reference
              ? {"font-weight": "700", opacity: "0.85"}
              : {};
          }
        },
        {
          name: "toggle",
          title: "enabled",
          parser: (item) => {
            return `${String.fromCharCode(
              122 + parseInt(item.enabled ? 0 : 1)
            )} ${this.$t(item.enabled ? "yes" : "no")} ${
              item.enabled ? "true" : "false"
            }`;
          },
          style() {
            return {"text-align": "center"};
          }
        }
      ];
    },
    commands() {
      if (!this) return [];
      let items = [
        {
          name: "create",
          title: "new",
          icon: "fa fa-plus",
          enabled: () => {
            return (
              this.$can("manage", "EstacaoCadastro") &&
              !this.cloneEnabled &&
              (!this.connector || !this.connector.base_model_id)
            );
          },
          commands: []
        },
        {
          name: "edit",
          title: "edit",
          icon: "fa fa-pencil",
          enabled: (item) => {
            return (
              !this.nSelected &&
              this.$can("manage", "EstacaoEscrita") &&
              !this.cloneEnabled
            );
          }
        },
        {
          name: "clone",
          title: "clone",
          icon: "fa fa-copy",
          enabled: (item) => {
            if (
              !this.$can("manage", "EstacaoCadastro") ||
              this.cloneEnabled ||
              (this.isMQTT && this.isItsOwnCollector(item))
            )
              return false;
            return !this.nSelected && !this?.connector?.base_model_id;
          }
        },
        {
          name: "remove",
          title: "remove",
          icon: "fa fa-trash",
          enabled: (item) => {
            if (
              this.nSelected ||
              !this.$can("manage", "EstacaoCadastro") ||
              this.cloneEnabled
            )
              return false;
            return this.isRemovable(item);
          }
        },
        {name: "-"},
        {
          name: "open",
          title: "data_list",
          icon: "glyphicon glyphicon-stats",
          enabled: () => {
            return !this.nSelected && !this.cloneEnabled;
          }
        },
        {
          name: "addChild",
          title: this.$t("add_new_data"),
          icon: "fa fa-plus",
          enabled: (item) => {
            if (!this.$can("manage", "EstacaoCadastro") || this.cloneEnabled)
              return false;
            return (
              !this.nSelected &&
              !this?.connector?.base_model_id &&
              !item.reference_device_id
            );
          }
        }
      ];
      if (this.cloneEnabled || this?.connector?.base_model_id) return items;
      if (this.$can("manage", "EquipamentoCadastro"))
        items[0].commands.push({
          name: "newConnector",
          title: "new_connector",
          icon: "fa fa-plus"
        });
      if (!(this.$store.getters["dashboard/connectorList"] || []).length)
        return items;

      if (this.$can("manage", "EstacaoCadastro"))
        items[0].commands.push({
          name: "newDevice",
          title: "new_device",
          icon: "fa fa-plus"
        });
      if (this.$can("manage", "DadoCadastro"))
        items[0].commands.push({
          name: "newData",
          title: "new_data",
          icon: "fa fa-plus"
        });
      if (!this.isFreePlan && this.$can("manage", "AlarmeCadastro"))
        items[0].commands.push({
          name: "newAlarm",
          title: "new_alarm",
          icon: "fa fa-plus"
        });
      if (this.$can("manage", "EstacaoCadastro") && !this.hideImport())
        items[0].commands.push({
          name: "importDevices",
          title: "import_devices",
          icon: "fa fa-plus"
        });
      if (this.$can("manage", "DadoCadastro") && !this.hideImport())
        items[0].commands.push({
          name: "importData",
          title: "import_data",
          icon: "fa fa-plus"
        });
      if (
        !this.isFreePlan &&
        this.$can("manage", "AlarmeCadastro") &&
        !this.hideImport()
      )
        items[0].commands.push({
          name: "importAlarms",
          title: "import_alarms",
          icon: "fa fa-plus"
        });
      return items;
    },
    list() {
      return this.items;
    },
    newItemPath() {
      return `/dashboard/edit/connector/${this.connector_id || 0}/device/0`;
    },
    connector() {
      return (
        this?.parent ||
        (this.$store.getters["dashboard/connectorList"] || []).find(
          ({id}) => id == this.connector_id
        )
      );
    },
    isMassRemovalDisabled() {
      let ret =
        !this.items || !this.nSelected || this.nSelected == this.items.length;
      if (!ret) {
        let removable = this.items.filter(
          (item) =>
            this.multiSelection.values.indexOf(item.id) != -1 &&
            this.isRemovable(item)
        ).length;
        return !(removable && this.items.length - removable > 0);
      }
      return ret;
    }
  },
  watch: {
    parent(n) {
      if (n) {
        this.$set(this, "nav", {
          previous: `/dashboard/edit/connector/`,
          items: [
            {
              name: "connector_plural",
              url: "/dashboard/edit/connector"
            },
            {
              name: n.name,
              url: `/dashboard/edit/connector/${n.id}`
            },
            {
              name: "device_plural",
              url: `/dashboard/edit/connector/${n.id}/device`
            }
          ]
        });
      }
    },
    connector_id(n, o) {
      if (o && n && n != o) {
        this.items = null;
        this.buildItems();
      }
    },
    deviceList: {
      handler(n, o) {
        if (n && !o) this.ready = true;
        this.buildItems();
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    buildItems() {
      let result;
      if (this.deviceList) {
        if (this.deviceList.length) {
          try {
            result = structuredClone(this.deviceList);
          } catch (error) {
            result = JSON.parse(JSON.stringify(this.deviceList));
          }
          if (result && !this.items) {
            this.onResposeReady(result);
            var first = (this.items || [])[0];
            if (first?.connector) this.$set(this, "parent", first.connector);
            this.$emit("loading", false);
          }
        } else {
          this.items = null;
        }
      }
    },
    removalMessage(item) {
      let msg = "";

      // In mass remove mode
      if (item && Array.isArray(item)) {
        return this.warningContent(
          "device",
          this.$tc("n_records_selected", item.length, {
            amount: item.length
          }),
          "you_wont_be_able_to_revert_this"
        );
      }

      if (this?.connector?.base_model) {
        let message = this.$t("removal_model_message");
        let text = this.$t("you_wont_be_able_to_revert_this");
        let field_name = this.$tc("device");
        let value = item.name;
        let cls = "fa fa-exclamation-triangle";
        let warning = `<p>${message}<br/><div class="text-warning"><i class="${cls}"></i> ${text}</div></p>`;
        let html = `<b>${field_name}</b>: ${value}${warning}`;
        msg = this.wrap(html); // can be implemented at child level
      }
      return msg;
    },
    create(command) {
      if (this.isModel) {
        this.$router.push(
          "/dashboard/edit/connector/" + this.connector_id + "/device/0"
        );
        return;
      }
      this.validateResourceQuota(
        "device_plural",
        (this.contract && this.contract.maximum_connectors) || 1, // there is still no max_device on contract
        (this.contract && this.contract.registered_connectors) || 0
      ).then((resp) => {
        if (resp == "proceed") {
          this.$router.push(
            "/dashboard/edit/connector/" + this.connector_id + "/device/0"
          );
        } else if (resp == "upgrade") {
          this.$router.push("/dashboard/plan");
        }
      });
    },
    open(command) {
      this.$router.push(
        "/dashboard/edit/connector/" +
          this.connector_id +
          "/device/" +
          command.target.id +
          "/data"
      );
    },
    edit(command) {
      this.$router.push(
        "/dashboard/edit/connector/" +
          this.connector_id +
          "/device/" +
          command.target.id
      );
    },
    clone(command) {
      this.$router.push(
        "/dashboard/edit/connector/" +
          this.connector_id +
          "/device/" +
          command.target.id +
          "?a=c"
      );
    },
    hideImport() {
      return !this.connector || this?.connector?.base_model_id != null;
    },
    remove(command) {
      let device = command.target;
      let config = {
        item: device,
        type: "device",
        resource_key: "device_id",
        rule: "EstacaoEscrita",
        service: this.service
      };
      this.validateAndRemove(config, (removed) => {
        if (!removed) return;
        let lst;
        if (!this?.connector?.model_base) {
          /// any dependency would change remote etag, it is safe to refresh them
          this.fetchItems();
          this.$emit("devices", this.items);
          return;
        }
        // virtual device dependencies (model only)
        let items = this.items;
        lst = (this.$store.getters["dashboard/deviceList"] || [])
          .filter(
            ({data_collector_device_id}) =>
              data_collector_device_id == device.id
          )
          .map(({id, reference_id}) => {
            items = items.filter((item) => item.id != id);
            return reference_id;
          })
          .concat([device.reference_id]);
        if (lst.length) {
          // since several other instances may have these devices, they also needs to be updated
          lst = (this.$store.getters["dashboard/deviceList"] || [])
            .filter(({reference_id}) => lst.indexOf(reference_id) >= 0)
            .map(({id}) => ({device_id: id}));
          this.$set(this, "items", items);
          this.$store.dispatch("dashboard/removeResources", lst);
          this.$emit("devices", this.items);
        }
      });
    },
    massRemove(command) {
      let doMassRemove = (lst) => {
        let config = {
          items: lst.map(({id}) => id),
          type: "device",
          resource_key: "device_id",
          rule: "EstacaoEscrita",
          service: this.service
        };
        let callback = (removed) => {
          if (!removed) return;
          // any dependency would change remote etag, it is safe to refresh them
          this.fetchItems();
          this.multiSelection.values = [];
          this.$emit("devices", this.items);
        };
        this.validateAndMassRemove(config, callback);
      };
      let removableList = this.list.filter(
        (item) =>
          (this.multiSelection.values || []).indexOf(item.id) != -1 &&
          this.isRemovable(item)
      );
      if (
        !removableList.length ||
        this.multiSelection.values.length > removableList.length
      ) {
        this.$swal({
          title: this.$t("some_selected_items_cant_be_removed"),
          text: this.$t("wish_to_unselect"),
          icon: "warning",
          buttons: [this.$t("cancel"), this.$t("yes_proceed")]
        }).then((result) => {
          if (!result) return;
          this.multiSelection.values =
            (removableList.length && removableList.map(({id}) => id)) || [];
          if (
            this.multiSelection.values.length &&
            this.items.length - this.multiSelection.values.length
          ) {
            doMassRemove(removableList);
          }
        });
      } else {
        doMassRemove(removableList);
      }
    },
    addChild(command) {
      this.$router.push(
        "/dashboard/edit/connector/" +
          this.connector_id +
          "/device/" +
          command.target.id +
          "/data/0"
      );
    },
    fetchItems() {
      let query = {
        contract_id: this.contract.id
      };
      if (this.connector_id) query.connector_id = this.connector_id;
      this.fetch(query).then(() => {
        if (this.items.length) {
          deviceListAdapter(this.items);
          this.$set(this, "parent", this.items[0]?.connector);
        } else {
          if (this.connector_id) {
            let srv = new ConnectorService();
            srv
              .get(this.connector_id, this.$store.getters["user/contract_id"])
              .then((result) => {
                if (result) {
                  this.$set(this, "parent", result);
                }
              });
          }
        }
        self.busy = false;
        this.$emit("devices", self.items);
      });
    },
    isItsOwnCollector(item) {
      return (
        !item.data_collector_device_id ||
        parseInt(item.data_collector_device_id) == parseInt(item.id)
      ) ? true : false;
    },
    isRemovable(item) {
      if ((this.items || []).length <= 1) return false;
      if (item.data_collector_device_id) {
        if (this.isItsOwnCollector(item)) {
          // its own collector
          return !this.items.some(
            ({id, data_collector_device_id}) =>
              parseInt(id) != parseInt(item.id) &&
              parseInt(data_collector_device_id) == parseInt(item.id)
          );
        } else {
          // virtual device
          return true;
        }
      }
      return !this?.connector?.base_model_id;
    },
    selectionHint(item) {
      return !this.isRemovable(item)
        ? `${this.$t("remove_this_item")}: ${this.$t("not_allowed")}`
        : `${this.$t("select")}`;
    },
    log() {
      if (!this.connector_id) return;
      console.table(
        Object.fromEntries(
          this.$store.getters["dashboard/deviceList"]
            .filter(
              ({connector_id}) =>
                parseInt(connector_id) == parseInt(this.connector_id)
            )
            .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
            .map(({id, name, reference_id}) => [id, {reference_id, name}])
        )
      );
    },
    onItemToggled(item) {
      if (!item) return;
      this.items.forEach((i) => {
        if (parseInt(i.data_collector_device_id) == parseInt(item.id)) {
          i.enabled = item.enabled;
        }
      });
    }
  },
  beforeCreate() {
    this.service = new DeviceService();
    this.$emit("rule", "EstacaoAcesso");
  },
  created() {
    this.multiSelection.hint = this.selectionHint;
  }
};
</script>

<style scoped>
section > .tab-pane {
  margin: 0 0 30px 0;
  padding: 0 15px 50px 15px;
}
.searchable-table {
  padding-bottom: 100px;
}

.skin-dark .info-box {
  background-color: var(--skin-dark-medium);
  color: var(--skin-dark-light);
  border-color: var(--skin-dark-dark);
}
.skin-dark .filter-option.noselect {
  color: var(--skin-dark-light);
}
</style>
